/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import get from 'lodash/get';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import RichTextParserPropTypes from './RichTextParserPropTypes';
import ContentModule from '../ContentModule';
import Asset from '../Asset';
import ElementLink from '../ElementLink';

function RichTextParser({ document, _contentTypeId}) {
  const options = {
    renderText: (text) =>
      text.split('\n').reduce((children, textSegment, idx) => [...children, idx > 0 && <br />, textSegment], []),
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const target = get(node, 'data.target');

        if (!target || !target._contentTypeId) return null;

        return (
          <section className="section">
            <Asset {...target} />
          </section>
        );
      },
      [BLOCKS.INLINE_ASSET]: (node) => {
        const target = get(node, 'data.target');

        if (!target || !target._contentTypeId) return null;

        return (
          <section className="sub-section">
            <Asset {...target} />
          </section>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const target = get(node, 'data.target');

        if (!target || !target._contentTypeId) return null;

        if (target._contentTypeId === 'elementLink' && target.destinationType === 'Set Anchor Tag') {
          // Manual url only accepts http urls
          return <span id={target?.linkText?.toLowerCase()?.replace(/ /g, '-')} />;
        }

        return (
          <section className="section">
            <ContentModule contentTypeId={target._contentTypeId} fields={target} parent_contentTypeId={_contentTypeId}/>
          </section> 
        );
      },
      [BLOCKS.INLINE_ENTRY]: (node) => {
        const target = get(node, 'data.target');

        if (!target || !target._contentTypeId) return null;

        return (
          <section className="sub-section">
            <ContentModule contentTypeId={target._contentTypeId} fields={target} />
          </section>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const target = get(node, 'data.target');
        const content = get(node, 'content[0].value');

        if (!content || !target || !target._contentTypeId) return null;

        const { _id, _href, _as } = target;

        return (
          <ElementLink id={_id} as={_as} href={_href} linkText={content} trackingId={`article_${_href}`}>
            {content}
          </ElementLink>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const target = get(node, 'data.target');
        const content = get(node, 'content[0].value');

        if (!content || !target || !target.url) return null;

        return (
          <ElementLink download target="_blank" as={target.url} href={target.url} linkText={content} trackingId={`article_${target.url}`}>
            {content}
          </ElementLink>
        );
      }
    }
  };

  return documentToReactComponents(document, options);
}

RichTextParser.propTypes = RichTextParserPropTypes;

export default RichTextParser;
