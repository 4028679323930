/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import cx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import sidekick from '@last-rev/contentful-sidekick-util';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ElementVideo, { ElementVideoPropTypes } from '../ElementVideo';
import RichTextParser from '../RichTextParser/RichTextParser';
import RichTextParserPropTypes from '../RichTextParser/RichTextParserPropTypes';
import ContentModule from '../ContentModule';
import styles from './PageVideo.module.scss';
import { useRouter } from 'next/router';
import { useState } from 'react';

export const PageVideoPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  video: PropTypes.shape(ElementVideoPropTypes).isRequired,
  videoTitle: PropTypes.string,
  videoSummary: PropTypes.string,
  transcript: PropTypes.shape(RichTextParserPropTypes),
  contentSections: PropTypes.array,
  wistiaVideo: PropTypes.object,
  nextSlug: PropTypes.string
};

function PageVideo({
  _id,
  _contentTypeId,
  pageTitle,
  video,
  videoTitle,
  videoSummary,
  transcript,
  contentSections,
  wistiaVideo,
  nextSlug
}) {
  let it;
  const {description}=video
  if(typeof description === "object"){
    ({it}=description)
  }
  else{
    it=""
  }

  const [isOpen, setOpen] = React.useState(false);
  const [wistiaTitle, setWistiaTitle] = React.useState('');
  const [wistiaDescription, setWistiaDescription] = React.useState('');
  const toggleClass = () => setOpen(!isOpen);
  const { t } = useTranslation();
  const router = useRouter();

  React.useEffect(() => {
    if (!videoTitle) {
      setWistiaTitle(wistiaVideo.video?.name);
    }
    if (!videoSummary) {
      setWistiaDescription(wistiaVideo.video?.description.replace(/(<([^>]+)>)/gi, ''));
    }
  }, [videoTitle, videoSummary, wistiaVideo]);




  const handleBackToVideos = () => {
    router.push('/video-library');
  };

  const handleNextVideo = () => {
    router.push(`/video-library/${nextSlug}`);
  };
  
  return (
    <div className={styles.mainWrap}>
      <div className={styles.Buttons}>
        <button className={`btn btn-primary ${styles.backButton}`} onClick={handleBackToVideos} data-uxa-log={`video_back`} data-uxa-interactions="click shown">
          Back to video library
        </button>
        {nextSlug && (
          <button className={`btn btn-primary ${styles.nextButton}`} onClick={handleNextVideo} data-uxa-log={`video_next`} data-uxa-interactions="click shown">
            Next video
          </button>
        )}
      </div>
      <section
        key="PageVideo"
        data-testid="PageVideo"
        className={styles.pageContent}
        {...sidekick(_id, null, _contentTypeId, 'Page Video')}>
        <Head>
          {/* eslint-disable-next-line react/no-danger */}
        </Head>

        <h1 data-testid="PageVideo-pageTitle" className={styles.sectionTitle}>
          {pageTitle}
        </h1>

        {video ? (
          <div className="mb-5 pb-3" data-testid="PageVideo-video">
            <ElementVideo {...video} />
          </div>
        ) : null}

        {/* Element - Video: Description */}
        {video?.description || videoSummary ? (
          <p data-testid="PageVideo-videoSummary" className={styles.videoSummary} itemProp="description">
            {video?.description==="string"? description: it ?? videoSummary}
          </p>
        ) : null}

        {!videoSummary && !video?.description && wistiaDescription ? (
          <p
            data-testid="PageVideo-videoSummary"
            className={styles.videoSummary}
            itemProp="description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: wistiaDescription }}
          />
        ) : null}

        {/* Element - Video: Title */}
        {video?.title || wistiaTitle ? (
          <h2 data-testid="PageVideo-videoTitle" className={styles.videoTitle} itemProp="name">
            {video?.title ?? wistiaTitle}
          </h2>
        ) : null}

        {transcript?.content && transcript?.content.length > 0 ? (
          <div className={styles.transcript} itemProp="transcript">
            <div className={cx(styles.transcriptWrap, styles.open)} data-testid="PageVideo-transcript">
              <RichTextParser document={transcript} _contentTypeId={_contentTypeId}/>
            </div>
            {/* {filteredArray&& filteredArray.length>0 || entriBlock && entriBlock.length>0 ? */}
          </div>
        ) : null}


      </section>

      {contentSections?.length ? (
        <section
          key="PageVideo-contentSections"
          className={cx(styles.helpSection, 'col-24 mt-5')}
          {...sidekick(_id, 'contentSections', _contentTypeId, 'Content Sections')}>
          {contentSections.map((module) => (
            <ContentModule wrapSection contentTypeId={module._contentTypeId} fields={module} key={module._id} />
          ))}
        </section>
      ) : null}
    </div>
  );
}

PageVideo.propTypes = PageVideoPropTypes;

PageVideo.defaultProps = {
  videoTitle: null,
  videoSummary: null,
  transcript: null,
  contentSections: [],
  wistiaVideo: {},
  nextSlug: null
};

export default PageVideo;
