
import { object } from 'prop-types';
import {
  CHECKED_CHECKLIST,
  UNCHECKED_CHECKLIST,
  ONLOAD_CHECKLIST
} from './checklist.types';

export const initialState = {
  itemchecked: true,
  checklistItems: {}
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHECKED_CHECKLIST: {
      const { id, checkedResponse } = payload;
      return {
        ...state,
        itemchecked: false,
        checklistItems: {
          ...state.checklistItems,
          userid: id,
          items: checkedResponse
        }

      }
    };


    case UNCHECKED_CHECKLIST: {
      const { id, unchekedResponse } = payload;

      return {
        ...state,
        itemchecked: false,
        checklistItems: {
          ...state.checklistItems,
          userid: id,
          items: unchekedResponse
        }
      }
    }
    case ONLOAD_CHECKLIST: {
      const { data } = payload;
      return {
        ...state,
        checklistItems: {
          ...state.checklistItems,
          ...data
        }
      };
    }

    default:
      return state;
  }
}
