import React, { useState } from "react";

import styles from "./ModuleAccordion.module.scss";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { useRouter } from "next/router";
import RichTextParser from "../RichTextParser";
import ReactMarkdown from 'react-markdown';

function ModulEachAccordian({ _id, contentTypeId, headerText, summaryText, summaryRichText, key, index, openIndex, setOpenIndex,parent_contentTypeId}) {

    const router = useRouter();
    console.log("routerr", router, contentTypeId,parent_contentTypeId)
    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    }

    return (
        <div class={styles._id}>
            <>
                <div className={styles.wrapcontainer} onClick={() => handleToggle(index)}>
                    <h3 className={styles.headertext}>{headerText}</h3>
                    <button className={parent_contentTypeId === "pageCourseTopic" ?styles.arrowbutton :styles.newarrowbutton}>
                        {openIndex === index ? <SlArrowUp /> : <SlArrowDown />}
                    </button>
                </div>

                <div className={`${styles.slideSummaryText} ${openIndex === index ? styles.visible : styles.hidden}`}>
                    {summaryRichText ? (
                        <RichTextParser document={summaryRichText} />
                    ) : (
                        <ReactMarkdown>{summaryText}</ReactMarkdown>
                    )}
                </div>
            </>
            <hr />
        </div>

    )




}






export default ModulEachAccordian