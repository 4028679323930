import tealium from './tealium';

export default function pithos() {
  /**
   * @param {string} cookieName - The name of the cookie you want the value of
   * @param {boolean} returnObject - Whether to return the value as an object or a string
   * @returns {(string|Object)} - The value of the cookie
   */


  setTimeout(() => {
    if(typeof _satellite !== 'undefined' && _satellite) {
      console.log('inside pithos : satellite obj');
      } else {
      console.log('inside pithos : satellite not defined');
      }
  }, 2000);

  function getCookieValue(cookieName, returnObject = false) {
    if (document.cookie.includes(`${cookieName}=`)) {
      const value = document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${cookieName}=`))
        .split('=')[1];
      return returnObject ? JSON.parse(value) : value;
    }
    return null;
  }

  /**
   * Map of categories to IDs. The string ids come from Crownpeak
   * and are based off of the categories we configured in Evidon.
   * They should not change as long as we don't modify the category names
   *     Key:   The category string we use in Tealium to trigger load rules
   *     Value: The category ID for the evidon site notice
   */
  const tealiumCategoryMap = {
    StrictlyNecessary: 'strictly necessary',
    Analytics: 'analytics',
    MarketingAdvertising: 'general marketing and advertising',
    PerformanceFunctionality: 'performance and functionality',
    SocialMediaAdvertising: 'social media advertising',
    AllCookies: 'all'
  };

  let categoriesUpdated = false;
  const isTealiumEnabled = true;
  const SHADOW_COOKIE_NAME = '__Secure-dbx_consent';

  // Checks if the cookie category settings have changed
  function havePreferencesChanged(newCategories) {
    const currentCookieObj = getCookieValue(SHADOW_COOKIE_NAME, true) || {};
    if (currentCookieObj.categories) {
      try {
        const currentCategories = JSON.stringify(currentCookieObj.categories);
        return JSON.stringify(newCategories) !== currentCategories;
      } catch {
        return false;
      }
    }
    return false;
  }

  // Pushing user consent category data to Tealium data layer
  function updateDataLayerWithCategories(consentData, usingTealium) {
    console.log('updateDataLayerWithCategories is getting called' );
    categoriesUpdated = false;
    let cookieCategories = [];
    const incomingCategories = consentData.categories;

    if (incomingCategories) {
      if (incomingCategories.all === true) {
        cookieCategories = [...Object.keys(tealiumCategoryMap)];
      } else {
        Object.keys(tealiumCategoryMap).forEach((key) => {
          if (incomingCategories[tealiumCategoryMap[key]] === true) {
            cookieCategories.push(key);
          }
        });
      }
    }

    if (usingTealium) {
      if(window._satellite) {  
        if (window._satellite.consent_categories === undefined) {
          window._satellite.consent_categories = {};
        }
        window._satellite.consent_categories = cookieCategories.join(',');
      } 
    }
  }

  const embeddableConfig = {
    injectToFooter: true,
    injectFloatingButton: true,
    disableBanner: false,

    consentChangedCallback: (categories) => {
      console.log('consentChangedCallback is getting called' );
      const consentData = {
        action: 'CONSENT_CHANGED',
        categories
      };

      /**
       * Check to see if newly changed consent settings match the preferences stored in cookie
       * Changes Exist = Set new shadow cookie & push categories to Tealium Data Layer
       * No Changes = Assume user already consented previously, don't update the existing cookie
       */
      if (havePreferencesChanged(consentData.categories)) {
        updateDataLayerWithCategories(consentData);
      }
    },
    priorConsentCallback: (categories, preferencesChanged) => {
      console.log('priorConsentCallback is getting called' );

      const consentData = {
        action: 'PRIOR_CONSENT',
        categories
      };

      updateDataLayerWithCategories(consentData, isTealiumEnabled);

      if (isTealiumEnabled) {
        if (!global.window._satellite) {
          tealium();
        }
      }

      // When user accept the consent, as now cookie banner
      // is not there so remove the margin bottom.
      if (document.querySelector('[data-testid=GlobalFooter]') != null) {
        document.querySelector('[data-testid=GlobalFooter]').style['padding-bottom'] = '4.625rem';
      }
    },
    consentDeclinedCallback: () => {
      // When user decline the consent, as now cookie banner
      // is not there so remove the margin bottom.
      console.log('consentDeclinedCallback is getting called');
      if (document.querySelector('[data-testid=GlobalFooter]') != null) {
        document.querySelector('[data-testid=GlobalFooter]').style.removeProperty('margin-bottom');
      }
    }
  };

  if (window.dropbox === undefined) {
    window.dropbox = {
      privacyConsent: {
        autoInit: embeddableConfig
      }
    };
  }

  const script = document.createElement('script');
  script.src = 'https://www.dropbox.com/pithos/privacy_consent';
  script.async = true;
  document.body.appendChild(script);

  // Initial load
  if (!categoriesUpdated) {
    updateDataLayerWithCategories({ categories: ['StrictlyNecessary'] }, isTealiumEnabled);
    if (isTealiumEnabled) {
      tealium();
    }
  }
}