import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WistiaPlayer from 'react-player/wistia';
import YouTubePlayer from 'react-player/youtube';
import styles from './ElementWistiaVideo.module.scss';

export const ElementWistiaVideoPropTypes = {
  videoId: PropTypes.string.isRequired,
  isYouTube: PropTypes.bool,
  playsInline: PropTypes.bool
};

function ElementWistiaVideo({ videoId, isYouTube, playsInline }) {
  const options = {
    wistia: {
      videoFoam: true,
      popover: playsInline,
      controls: true
    },
    youtube: {
      playerVars: {
        playsinline: playsInline
      }
    }
  };

  const popOverOptions = [
    `popover=${playsInline}`,
    'popoverOverlayColor=#000000',
    'popoverOverlayOpacity=1.0',
    'popoverAnimation=fade',
    'popoverPreventScroll=true',
    'popoverBoxShadow=false'
  ];

  const url = isYouTube
    ? `https://www.youtube.com/watch?v=${videoId}`
    : `//fast.wistia.com/medias/${videoId} ${popOverOptions.join(' ')}`;

  const ReactPlayer = isYouTube ? YouTubePlayer : WistiaPlayer;
  const [currentPageUrl, setCurrentPageUrl] = useState('');

  useEffect(() => {
    // This code runs only on the client side
    const path = window.location.pathname.split('/').pop();
    setCurrentPageUrl(path);
  }, []);

  useEffect(() => {
    // Function to add data attribute to buttons within the specified div
    const addDataAttributeToButtons = () => {
      const videoWraps = document.querySelectorAll('div[data-testid="ElementWistiaVideoWrap"]');
      if (videoWraps.length > 0) {
        videoWraps.forEach(videoWrap => {
          const buttons = videoWrap.querySelectorAll('button');
          console.log("Button Length", buttons.length);
          buttons.forEach(button => {
            const ariaLabel = button.getAttribute('aria-label');
            if (ariaLabel) {
              const formattedLabel = ariaLabel
                .replace(/[^a-zA-Z0-9 ]/g, '-')
                .replace(/\s+/g, '-')
                .toLowerCase();
              button.setAttribute('data-uxa-log', `wistiavideo-${formattedLabel}/${currentPageUrl}`);
            } else {
              button.setAttribute('data-uxa-log', `wistiavideo-${currentPageUrl}`);
            }
            button.setAttribute('data-uxa-interactions', `click shown`);
          });
        });
      } else {
        console.warn('No elements with data-testid="ElementWistiaVideoWrap" found.');
      }
    };

    // Call the function once after all elements are loaded
    const handleLoad = () => {
      addDataAttributeToButtons();
      // Remove the event listener after it has been called
      window.removeEventListener('load', handleLoad);
    };

    // Call the function initially
    addDataAttributeToButtons();

    // Observe changes in the DOM or props/state
    const observer = new MutationObserver(() => {
      addDataAttributeToButtons();
    });

    const videoWrap = document.querySelector('div[data-testid="ElementWistiaVideoWrap"]');
    if (videoWrap) {
      observer.observe(videoWrap, { childList: true, subtree: true });
    }

    // Cleanup function to disconnect the observer
    return () => {
      observer.disconnect();
    };
  }, [currentPageUrl]); // Dependency array includes currentPageUrl to run when it changes

  return (
    <div className={styles.iframeWrap} data-testid="ElementWistiaVideoWrap">
      <ReactPlayer url={url} config={options} className={`${styles.wistiaEmbed}`} controls />
    </div>
  );
}

ElementWistiaVideo.propTypes = ElementWistiaVideoPropTypes;

ElementWistiaVideo.defaultProps = {
  isYouTube: false,
  playsInline: false
};

export default ElementWistiaVideo;