
import {
  CHECKED_CHECKLIST,
  UNCHECKED_CHECKLIST,
  ONLOAD_CHECKLIST
} from './checklist.types';

export const checkedChecklist = (id, _id) => async (dispatch, getState) => {
  const stateData = getState()
  const { user, checklist } = stateData
  let valueOfchecked = {};
  if (Object.keys(checklist?.checklistItems).length > 0) {

    valueOfchecked = checklist?.checklistItems?.items
    if (!valueOfchecked.hasOwnProperty(_id)) {
      valueOfchecked[_id] = true
    } else {
      valueOfchecked[_id] = true
    }

  }
  else {
    valueOfchecked[_id] = true
  }
  const chekedData = await fetchChecklistData(id, valueOfchecked);
  const checkedResponse = chekedData?.items

  dispatch({
    type: CHECKED_CHECKLIST,
    payload: {
      id,
      checkedResponse
    }
  })
};

export const uncheckedChecklist = (id, _id) => async (dispatch, getState) => {
  const stateData = getState()
  const { user, checklist } = stateData
  let valueOfunchecked;
  if (Object.keys(checklist?.checklistItems).length > 0) {

    valueOfunchecked = checklist?.checklistItems?.items
    if (valueOfunchecked.hasOwnProperty(_id)) {
      valueOfunchecked[_id] = false
    }

  }
  else {
    valueOfunchecked = {}
  }
  const unchekedData = await fetchChecklistData(id, valueOfunchecked);
  const unchekedResponse = unchekedData?.items
  dispatch({
    type: UNCHECKED_CHECKLIST,
    payload: {
      id,
      unchekedResponse
    }
  })
};
export const onloadDatafetching = () => async (dispatch, getState) => {
  const stateData = getState()
  const { user} = stateData
  let value={}
  try {
    const data = await fetchChecklistData(user?.id, value);
    dispatch({
      type: ONLOAD_CHECKLIST,
      payload: {
        data
      }
    })
  } catch (error) {
    console.log("Error in onloadDatafetching", error)
  }



}
const fetchChecklistData = async (user, value) => {
  try {
    const url = `${process.env.CHEKLIST_API}/check_list_items`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(process.env.CLIENT_ID +':'+process.env.CLIENT_SECRET)

    };
    const host = window.location.hostname;
    
    const body = JSON.stringify({
      "userid": `${user}`,
      "items": value,
      "dbtype": `${host && host.includes('learn-stage')? process.env.STG_DB : process.env.PROD_DB}`
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data1 = await response.json();
    
    const parseData = data1?.msg
    const requestObject = {
      userid: parseData?.userid,
      items: JSON.parse(parseData?.items)
    }
    return requestObject
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};