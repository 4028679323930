import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import styles from "./ModuleChecklist.module.scss";
import ModuleEachChecklist from "./ModuleEachChecklist";
import {onloadDatafetching } from '../../redux/modules/checklist';


function ModuleChecklist( props) {
    const {checklistItems,internalTitle}=props
    const dispatch= useDispatch()
    const user= useSelector(state=>state.user)
    
    useEffect(async () => {
        const fetchData = async () => {
          try {
            if(user && user?.id){
              dispatch(onloadDatafetching())
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [user])
   
   
    return (
        <div className={styles.checklistWrapper}>
                <h2 className={styles.internalTitle}>{internalTitle}</h2>
                {checklistItems && checklistItems.length>0 ?
                 checklistItems.map((ele, index) => {
                    return (
                        <div key={index}>
                            <ModuleEachChecklist
                               index={index}
                               _id={ele._id}
                               contentTypeId={ele._contentTypeId}
                               headerText={ele.headerText}
                               summaryRichText={ele.summaryRichText}
                               summaryText={ele.summaryText}
                               key={ele._id}
                               allchecklistItems={checklistItems}
                               />
                           
                        </div>
                    );
                }):null}
        </div>
    );
}

export default ModuleChecklist
